import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Inline from "../../../../../components/inline"
import InlineHelp from "../../../../../components/inline-help"
import Input from "../../../../../components/input"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Text from "../../../../../components/text"

import PoemFruehlingExplained from "../../../../../content/kurse/jahreszeiten/01-jahreszeitengedicht/poem-fruehling-explained"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "jahreszeiten",
          chapterId: "01-jahreszeitengedicht",
          taskId: "strophen-und-zeilen",
        })
        navigate(
          "/kurse/jahreszeiten/01-jahreszeitengedicht/strophen-und-zeilen/loesung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Strophen & Verse zählen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Strophen & Verse zählen
            </Heading>
          </Stack>
          <Paragraph>
            Wie ist das Gedicht geordnet und aufgebaut? Was fällt dir an seiner
            äußeren Form auf? Sieh es dir einmal genauer an.
          </Paragraph>

          <TaskSidebar
            main={
              <PaperStack>
                <PoemFruehlingExplained />
              </PaperStack>
            }
            sidebar={
              <Stack space={6}>
                <Note variant="task">
                  Zähle die Strophen und Verse des Gedichts und trage sie unten
                  ein.
                </Note>
                <InlineHelp title="Was sind nochmal Strophen & Verse?">
                  Als Verse bezeichnet man die Zeilen in einem Gedicht. Zusammen
                  ergeben die Verse eine Strophe. Ein Gedicht kann aus einer
                  oder auch mehreren Strophen bestehen.
                </InlineHelp>
                <Stack space={3}>
                  <Inline alignY="center">
                    <Text>Das Gedicht hat</Text>
                    <Box sx={{ width: 16 }}>
                      <Input
                        type="number"
                        id="strophen"
                        name="strophen"
                        placeholder="-"
                      />
                    </Box>
                    <Text>Strophen.</Text>
                  </Inline>
                  <Inline alignY="center">
                    <Text>Jede Strophe hat</Text>
                    <Box sx={{ width: 16 }}>
                      <Input
                        type="number"
                        id="zeilen"
                        name="zeilen"
                        placeholder="-"
                      />
                    </Box>
                    <Text>Verse.</Text>
                  </Inline>
                </Stack>
              </Stack>
            }
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
